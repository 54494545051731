// Styles
import "@mdi/font/css/materialdesignicons.css";
import "@/styles/main.scss";
// Vuetify
import { createVuetify, type ThemeDefinition } from "vuetify";

// const tokaen: ThemeDefinition = {
//   dark: false,
//   colors: {
//     primary: "#F4C10C",
//   },
// };
const darkTokaen: ThemeDefinition = {
  dark: true,
  colors: {
    primary: "#F4C10C",
  },
};

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: "darkTokaen",
    themes: {
      // tokaen,
      darkTokaen,
    },
  },
});
