import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex ga-4 mb-4" }
const _hoisted_2 = { class: "d-flex ga-4 mb-4" }
const _hoisted_3 = { class: "d-flex ga-4 mb-4" }
const _hoisted_4 = { class: "d-flex ga-4 mb-4" }
const _hoisted_5 = { class: "d-flex ga-4 mb-4" }
const _hoisted_6 = { class: "d-flex ga-4 mb-4" }

import { ref } from "vue";
import { IToken } from "@/utils/types";
import dexIcon from "@/assets/icons/sh-dex.svg";
import BlackCoinBullet from "@/components/Common/BlackCoinBullet.vue";
import { useDisplay } from "vuetify/lib/framework.mjs";


export default /*@__PURE__*/_defineComponent({
  __name: 'HowItWorksDialog',
  props: {
    token: {}
  },
  setup(__props: any) {

const props = __props;
const { smAndDown } = useDisplay();
const showDialog = ref<boolean>(false);

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_btn, {
      color: "primary",
      variant: "text",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (showDialog.value = true)),
      size: _unref(smAndDown) ? 'small' : undefined
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [
        _createTextVNode(" How it works? ")
      ])),
      _: 1
    }, 8, ["size"]),
    _createVNode(_component_v_dialog, {
      modelValue: showDialog.value,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((showDialog).value = $event)),
      "max-width": "500px",
      fullscreen: _unref(smAndDown)
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "d-flex flex-wrap align-center font-weight-black bg-primary py-4 text-uppercase" }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" How it works? ")
              ])),
              _: 1
            }),
            _createVNode(_component_v_card_text, { class: "px-4" }, {
              default: _withCtx(() => [
                _cache[17] || (_cache[17] = _createElementVNode("div", { class: "font-weight-black mb-6" }, " Tokensales Made Easy with Tokæn: Fair Launches, No Rug Pulls, Complete Control ", -1)),
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(BlackCoinBullet, { size: 30 }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createElementVNode("span", { class: "text-body-1 font-weight-black pl-1" }, "1", -1)
                    ])),
                    _: 1
                  }),
                  _cache[6] || (_cache[6] = _createElementVNode("div", null, [
                    _createElementVNode("div", { class: "text-h5" }, "Create or Pick a Token"),
                    _createElementVNode("div", { class: "text-body-1 flex-wrap" }, " Instantly launch your own token with Tokæn's user-friendly interface, or browse and choose among existing ones that sparks your interest. A portion of funds, secured in a liquidity pool on the æternity blockchain, ensures stability for ongoing transactions. ")
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(BlackCoinBullet, { size: 30 }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createElementVNode("span", { class: "text-body-1 font-weight-black pl-1" }, "2", -1)
                    ])),
                    _: 1
                  }),
                  _cache[8] || (_cache[8] = _createElementVNode("div", null, [
                    _createElementVNode("div", { class: "text-h5" }, "Buy on the Bonding Curve"),
                    _createElementVNode("div", { class: "text-body-1" }, " Purchase tokens using Tokæn's fair bonding curve, where prices are adjusted based on demand to ensure fair and transparent pricing. Plus, you can sell them back to the bonding curve at any time, with 90% of the AE backed at the same supply. ")
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(BlackCoinBullet, { size: 30 }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createElementVNode("span", { class: "text-body-1 font-weight-black pl-1" }, "3", -1)
                    ])),
                    _: 1
                  }),
                  _cache[10] || (_cache[10] = _createElementVNode("div", null, [
                    _createElementVNode("div", { class: "text-h5" }, "Participate in Community"),
                    _createElementVNode("div", { class: "text-body-1" }, " Governance (DAO) Every token created on Tokæn comes with its own DAO, allowing its holders to vote on proposals and make decisions about the token’s ecosystem. ")
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(BlackCoinBullet, { size: 30 }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createElementVNode("span", { class: "text-body-1 font-weight-black pl-1" }, "4", -1)
                    ])),
                    _: 1
                  }),
                  _cache[12] || (_cache[12] = _createElementVNode("div", null, [
                    _createElementVNode("div", { class: "text-h5" }, "Earn with the Affiliation"),
                    _createElementVNode("div", { class: "text-body-1" }, " System Share your invite code and earn up to 3.75% of the spread from transactions made by users you refer, including referrals from their invitees up to four layers deep. If no affiliation is present, this share is burned, benefiting all AE holders. ")
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(BlackCoinBullet, { size: 30 }, {
                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createElementVNode("span", { class: "text-body-1 font-weight-black pl-1" }, "5", -1)
                    ])),
                    _: 1
                  }),
                  _cache[14] || (_cache[14] = _createElementVNode("div", null, [
                    _createElementVNode("div", { class: "text-h5" }, "Gain Visibility and Rank"),
                    _createElementVNode("div", { class: "text-body-1" }, " As more users join, your token gains visibility and ranks higher, making it easier for others to compare and engage with it. ")
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(BlackCoinBullet, { size: 30 }, {
                    default: _withCtx(() => _cache[15] || (_cache[15] = [
                      _createElementVNode("span", { class: "text-body-1 font-weight-black pl-1" }, "6", -1)
                    ])),
                    _: 1
                  }),
                  _cache[16] || (_cache[16] = _createElementVNode("div", null, [
                    _createElementVNode("div", { class: "text-h5" }, "Sell Anytime"),
                    _createElementVNode("div", { class: "text-body-1" }, " Enjoy complete flexibility to sell tokens at any time, locking in profits or adjusting strategy as needed. ")
                  ], -1))
                ]),
                _cache[18] || (_cache[18] = _createElementVNode("div", { class: "d-flex ga-4 mb-4" }, [
                  _createElementVNode("div", { class: "text-body-1" }, " Tokæn ensures safe and fair token launches with no presales, no team allocations, and no funny business. Join us and take charge of your token journey today! Start now! 🚀 ")
                ], -1))
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, { class: "pa-0 mb-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  flat: "",
                  color: "primary",
                  variant: "elevated",
                  size: "x-large",
                  class: "px-12",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (showDialog.value = false))
                }, {
                  default: _withCtx(() => _cache[19] || (_cache[19] = [
                    _createTextVNode(" Close ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "fullscreen"])
  ]))
}
}

})