<template>
  <a
    class="copy-text"
    :class="{ 'has-icon': !hideIcon, disabled, bordered: !hideBorder }"
    @click.prevent="copyText($event)"
  >
    <div v-if="copied || copiedLocally" class="copied">
      {{ copiedText || "Copied!" }}
    </div>

    <div class="text-container">
      <slot>{{ value }}</slot>
    </div>

    <v-icon v-if="!hideIcon && !disabled" class="copy-icon-indicator">
      mdi-content-copy
    </v-icon>
  </a>
</template>

<script lang="ts">
import { useCopy } from "@/composables/copy";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CopyText",
  props: {
    value: { type: String, default: null },
    copiedText: { type: String, default: "" },
    copied: Boolean,
    disabled: Boolean,
    hideIcon: Boolean,
    hideBorder: Boolean,
  },
  setup(props) {
    const { copied: copiedLocally, copy } = useCopy();

    function copyText(event: MouseEvent) {
      if (!props.disabled) {
        copy(props.value);
        event.preventDefault();
      }
    }

    return {
      copiedLocally,
      copyText,
    };
  },
});
</script>

<style lang="scss" scoped>
.copy-text {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: inherit;
  width: 100%;
  overflow: hidden;

  &.bordered {
    border: 1px solid #f4c10c;
    padding: 8px 16px;
    border-radius: 20px;
  }

  .copy-icon-indicator,
  .copy-icon-copied {
    flex-shrink: 0;
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }

  .text-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 8px;
  }

  .copy-icon-copied {
    margin-left: 2px;
  }

  .copied {
    // @extend %face-sans-12-regular;

    // @include mixins.dashed-border;

    position: absolute;
    z-index: 1;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.8);
    text-transform: uppercase;
  }

  &.has-icon {
    padding-right: 24px;

    .copy-icon-indicator {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }

  &.disabled {
    cursor: inherit;
  }
}
</style>
