import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "copied"
}
const _hoisted_2 = { class: "text-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass(["copy-text", { 'has-icon': !_ctx.hideIcon, disabled: _ctx.disabled, bordered: !_ctx.hideBorder }]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.copyText($event)), ["prevent"]))
  }, [
    (_ctx.copied || _ctx.copiedLocally)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.copiedText || "Copied!"), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.value), 1)
      ], true)
    ]),
    (!_ctx.hideIcon && !_ctx.disabled)
      ? (_openBlock(), _createBlock(_component_v_icon, {
          key: 1,
          class: "copy-icon-indicator"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" mdi-content-copy ")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 2))
}