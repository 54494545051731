import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import TransactionQueue from "../views/TransactionQueue.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/tx-queue/:id",
    name: "tx-queue",
    component: TransactionQueue,
  },
  {
    path: "/sale/:contractAddress",
    name: "sale",
    component: () =>
      import(
        /* webpackChunkName: "token-sale-details" */ "../views/TokenSaleDetails.vue"
      ),
  },
  {
    path: "/create-token",
    name: "create-token",
    component: () =>
      import(
        /* webpackChunkName: "create-token" */ "../views/SelectCreateTokenCategoryView.vue"
      ),
  },
  {
    path: "/create-token/words",
    name: "create-token-word-category",
    component: () =>
      import(
        /* webpackChunkName: "create-token-word-category" */ "../views/CreateTokenCategories/WordTokenCreateView.vue"
      ),
  },
  {
    path: "/create-token/numbers",
    name: "create-token-number-category",
    component: () =>
      import(
        /* webpackChunkName: "create-token-number-category" */ "../views/CreateTokenCategories/NumericTokenCreateView.vue"
      ),
  },
  {
    path: "/invite",
    name: "invite",
    component: () =>
      import(/* webpackChunkName: "invite" */ "../views/InviteView.vue"),
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/accounts/:address",
    name: "account-details",
    component: () =>
      import(
        /* webpackChunkName: "account-details" */ "../views/Accounts/AccountDetails.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
