<template>
  <div>
    <v-btn
      color="primary"
      variant="text"
      @click="showDialog = true"
      :size="smAndDown ? 'small' : undefined"
    >
      How it works?
    </v-btn>

    <v-dialog v-model="showDialog" max-width="500px" :fullscreen="smAndDown">
      <v-card>
        <v-card-title
          class="d-flex flex-wrap align-center font-weight-black bg-primary py-4 text-uppercase"
        >
          How it works?
        </v-card-title>
        <v-card-text class="px-4">
          <div class="font-weight-black mb-6">
            Tokensales Made Easy with Tokæn: Fair Launches, No Rug Pulls,
            Complete Control
          </div>
          <div class="d-flex ga-4 mb-4">
            <BlackCoinBullet :size="30">
              <span class="text-body-1 font-weight-black pl-1">1</span>
            </BlackCoinBullet>
            <div>
              <div class="text-h5">Create or Pick a Token</div>
              <div class="text-body-1 flex-wrap">
                Instantly launch your own token with Tokæn's user-friendly
                interface, or browse and choose among existing ones that sparks
                your interest. A portion of funds, secured in a liquidity pool
                on the æternity blockchain, ensures stability for ongoing
                transactions.
              </div>
            </div>
          </div>
          <div class="d-flex ga-4 mb-4">
            <BlackCoinBullet :size="30">
              <span class="text-body-1 font-weight-black pl-1">2</span>
            </BlackCoinBullet>
            <div>
              <div class="text-h5">Buy on the Bonding Curve</div>
              <div class="text-body-1">
                Purchase tokens using Tokæn's fair bonding curve, where prices
                are adjusted based on demand to ensure fair and transparent
                pricing. Plus, you can sell them back to the bonding curve at
                any time, with 90% of the AE backed at the same supply.
              </div>
            </div>
          </div>
          <div class="d-flex ga-4 mb-4">
            <BlackCoinBullet :size="30">
              <span class="text-body-1 font-weight-black pl-1">3</span>
            </BlackCoinBullet>
            <div>
              <div class="text-h5">Participate in Community</div>
              <div class="text-body-1">
                Governance (DAO) Every token created on Tokæn comes with its own
                DAO, allowing its holders to vote on proposals and make
                decisions about the token’s ecosystem.
              </div>
            </div>
          </div>
          <div class="d-flex ga-4 mb-4">
            <BlackCoinBullet :size="30">
              <span class="text-body-1 font-weight-black pl-1">4</span>
            </BlackCoinBullet>
            <div>
              <div class="text-h5">Earn with the Affiliation</div>
              <div class="text-body-1">
                System Share your invite code and earn up to 3.75% of the spread
                from transactions made by users you refer, including referrals
                from their invitees up to four layers deep. If no affiliation is
                present, this share is burned, benefiting all AE holders.
              </div>
            </div>
          </div>
          <div class="d-flex ga-4 mb-4">
            <BlackCoinBullet :size="30">
              <span class="text-body-1 font-weight-black pl-1">5</span>
            </BlackCoinBullet>
            <div>
              <div class="text-h5">Gain Visibility and Rank</div>
              <div class="text-body-1">
                As more users join, your token gains visibility and ranks
                higher, making it easier for others to compare and engage with
                it.
              </div>
            </div>
          </div>
          <div class="d-flex ga-4 mb-4">
            <BlackCoinBullet :size="30">
              <span class="text-body-1 font-weight-black pl-1">6</span>
            </BlackCoinBullet>
            <div>
              <div class="text-h5">Sell Anytime</div>
              <div class="text-body-1">
                Enjoy complete flexibility to sell tokens at any time, locking
                in profits or adjusting strategy as needed.
              </div>
            </div>
          </div>
          <div class="d-flex ga-4 mb-4">
            <div class="text-body-1">
              Tokæn ensures safe and fair token launches with no presales, no
              team allocations, and no funny business. Join us and take charge
              of your token journey today! Start now! 🚀
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="pa-0 mb-0">
          <v-btn
            flat
            color="primary"
            variant="elevated"
            size="x-large"
            class="px-12"
            @click="showDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts" setup>
import { ref, defineProps } from "vue";
import { IToken } from "@/utils/types";
import dexIcon from "@/assets/icons/sh-dex.svg";
import BlackCoinBullet from "@/components/Common/BlackCoinBullet.vue";
import { useDisplay } from "vuetify/lib/framework.mjs";

const props = defineProps<{
  token: IToken;
}>();
const { smAndDown } = useDisplay();
const showDialog = ref<boolean>(false);
</script>
