<template>
  <div
    class="d-flex align-center justify-center"
    style="height: 100vh; width: 100vw"
  >
    <div class="text-center">
      <v-progress-circular indeterminate color="primary" size="80" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useTxQueueStore } from "@/stores/txQueue";
import { storeToRefs } from "pinia";
import { watch } from "vue";
import { useRoute } from "vue-router";
const { transactionsQueue } = storeToRefs(useTxQueueStore());

const route = useRoute();

watch(
  route,
  (_route) => {
    if (_route.params.id) {
      console.log("Transaction", _route.query.transaction, _route.params.id);
      transactionsQueue.value = {
        ...transactionsQueue.value,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [_route.params.id]: {
          ..._route.query,
        },
      };
      // close current tab
      setTimeout(() => {
        window.close();
      }, 200);
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
</script>
