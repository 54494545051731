import type { Encoded } from "@aeternity/aepp-sdk";

export function formatAddress(
  address: Encoded.AccountAddress | Encoded.TxHash | string
) {
  return `${address.slice(0, 7)}...${address.slice(-4)}`;
}

export const createOnAccountObject = (address: Encoded.AccountAddress) => ({
  address: () => address,
  sign: () => {
    throw new Error("Private key is not available");
  },
});
