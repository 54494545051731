import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

const invitationCode = ref();

export function useInvitation() {
  const route = useRoute();

  watch(
    () => route.query,
    (query) => {
      if (query.invite_code) {
        invitationCode.value = query.invite_code;
        localStorage.setItem("invite_code", invitationCode.value);
      }
    },
    { immediate: true, deep: true }
  );

  onMounted(() => {
    const code = localStorage.getItem("invite_code");
    if (code) {
      invitationCode.value = code;
    }
  });

  return {
    invitationCode,
  };
}
