<template>
  <v-btn v-if="false" @click="toggleDarkMode()" variant="text">
    <v-icon>mdi-theme-light-dark</v-icon>
  </v-btn>
</template>

<script lang="ts" setup>
import { useAppStore } from "@/stores/app";
const { toggleDarkMode } = useAppStore();
</script>
