import { DEFAULT_CURRENCY_CODE } from "@/utils/constants";
import { CurrencyCode, CurrencyRates, ICoin } from "@/utils/types";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useCurrenciesStore = defineStore(
  "currencies",
  () => {
    /**
     * AE Coin details with additional market info
     */
    const aeternityData = ref<ICoin>();

    /**
     * Stores the list of currencies with the AE coin fiat exchange rate for each of them.
     */
    const currencyRates = ref<CurrencyRates>({} as any);

    const currentCurrencyCode = ref<CurrencyCode>(DEFAULT_CURRENCY_CODE);

    return {
      aeternityData,
      currencyRates,
      currentCurrencyCode,
    };
  },
  { persist: true }
);
