import { defineStore } from "pinia";
import { ref } from "vue";

export const useTxQueueStore = defineStore(
  "tx-queue",
  () => {
    const transactionsQueue = ref<Record<string, any>>({});

    return { transactionsQueue };
  },
  {
    share: {
      enable: true,
    },
  }
);
