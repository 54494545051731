import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-uppercase" }

import { useCurrencies } from "@/composables/useCurrencies";
import { CURRENCIES } from "@/utils/constants";


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderCurrencySelector',
  setup(__props) {

const { setCurrentCurrency, currentCurrencyCode } = useCurrencies();

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_menu, { "max-height": "400px" }, {
      activator: _withCtx(({ props }) => [
        _createVNode(_component_v_chip, _normalizeProps(_guardReactiveProps(props)), {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(currentCurrencyCode)), 1),
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("mdi-chevron-down")
              ])),
              _: 1
            })
          ]),
          _: 2
        }, 1040)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_list, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(CURRENCIES), (currency) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: currency }, [
                (_unref(currentCurrencyCode) !== currency.code)
                  ? (_openBlock(), _createBlock(_component_v_list_item, {
                      key: 0,
                      value: currency,
                      onClick: ($event: any) => (_unref(setCurrentCurrency)(currency.code))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(currency.name), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["value", "onClick"]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})