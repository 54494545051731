import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import PriceFormatter from "@/components/Common/Pricing/PriceFormatter.vue";
import { useCurrencies } from "@/composables/useCurrencies";
import { Decimal } from "@/libs/decimal";
import { computed, PropType } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'LivePriceFormatter',
  props: {
  aePrice: {
    type: Object as PropType<Decimal>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const { getFiat } = useCurrencies();

const fiatPrice = computed(() => {
  return getFiat(props.aePrice);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PriceFormatter, _mergeProps({
    "ae-price": __props.aePrice,
    "fiat-price": fiatPrice.value
  }, _ctx.$attrs), null, 16, ["ae-price", "fiat-price"]))
}
}

})