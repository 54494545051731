import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-space-around"
}
const _hoisted_2 = {
  key: 1,
  class: "chart-container"
}
const _hoisted_3 = {
  key: 0,
  class: "timeframe-indicator"
}

import { watch } from "vue";
import { ISeriesApi, LineSeriesPartialOptions } from "lightweight-charts";
import { useChart } from "@/composables/useChart";
import { ref } from "vue";
import moment from "moment";
import { useQuery } from "@tanstack/vue-query";
import { TransactionHistoricalService } from "@/api/generated";


export default /*@__PURE__*/_defineComponent({
  __name: 'TokenLineChart',
  props: {
  saleAddress: {
    type: String,
    required: true,
  },
  height: {
    type: Number,
    default: 200,
  },
},
  setup(__props) {

const props = __props;
const loading = ref(true);
const lineSeries = ref<ISeriesApi<"Line">>();

const { data } = useQuery({
  queryFn: () =>
    TransactionHistoricalService.getForPreview({ address: props.saleAddress }),
  queryKey: ["TransactionHistoricalService.getForPreview", props.saleAddress],
});

const { chartContainer, chart } = useChart({
  height: props.height,
  chartOptions: {
    grid: {
      horzLines: {
        visible: false,
      },
      vertLines: {
        visible: false,
      },
    },
    timeScale: {
      visible: false,
    },
    crosshair: {
      vertLine: {
        visible: false,
      },
      horzLine: {
        visible: false,
      },
    },
    handleScale: false,
  },
  onChartReady: (chart) => {
    const seriesOptions: LineSeriesPartialOptions = {
      priceLineVisible: false,
      color: "rgb(244, 193, 12)",
      lineWidth: 2,
      crosshairMarkerVisible: false,
    };

    const _lineSeries = chart.addLineSeries(seriesOptions);
    _lineSeries?.priceScale().applyOptions({
      visible: false, // disables auto scaling based on visible content
      ticksVisible: false,
    });

    chart?.timeScale().fitContent();
    lineSeries.value = _lineSeries;

    console.log("chart ready data:::", data.value?.result);
    if (data.value?.result?.length) {
      updateSeriesData(data.value);
    }
  },
});

watch(data, (newData) => {
  if (!newData?.result?.length) {
    return;
  }
  lineSeries.value?.setData([]);

  updateSeriesData(newData);
});

function updateSeriesData(data: any[]) {
  let formattedData = data.result
    .map((item) => {
      return {
        time: moment(item.end_time).unix(),
        value: Number(item.last_price),
      };
    })
    .sort((a, b) => a.time - b.time);
  // if formattedData less than 5 generate more data with same value but with time - 1 hour
  if (formattedData.length < 10) {
    for (let i = 0; i < 10 - formattedData.length; i++) {
      const lastItem = formattedData[0];
      formattedData.unshift({
        time: lastItem.time - 3600000,
        value: lastItem.value,
      });
    }
  }
  lineSeries.value?.setData(formattedData);
  chart.value?.timeScale().fitContent();
}

return (_ctx: any,_cache: any) => {
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!

  return (!loading.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_skeleton_loader, {
          type: "ossein",
          width: "140",
          height: "80"
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", {
          ref_key: "chartContainer",
          ref: chartContainer,
          class: "lw-chart"
        }, null, 512),
        (_unref(data)?.timeframe)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(data).timeframe), 1))
          : _createCommentVNode("", true)
      ]))
}
}

})