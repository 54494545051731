import { useNetworkStore } from "@/stores/network";
import { INetworkTypes } from "@/utils/types";
import { storeToRefs } from "pinia";

export function useNetwork() {
  const { activeNetwork, activeNetworkId } = storeToRefs(useNetworkStore());

  function changeActiveNetwork(networkId: INetworkTypes) {
    activeNetworkId.value = networkId;
  }

  return {
    activeNetwork,
    activeNetworkId,
    changeActiveNetwork,
  };
}
