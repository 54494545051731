<template>
  <v-bottom-navigation id="bottom-navigation" grow fixed v-if="smAndDown">
    <v-btn :to="{ name: 'home' }" exact color="primary">
      <!-- <v-icon>mdi-home</v-icon> -->

      <span>Tokens</span>
    </v-btn>
    <v-btn :to="{ name: 'create-token' }" exact color="primary">
      <!-- <v-icon>mdi-bitcoin</v-icon> -->

      <span>Create Token</span>
    </v-btn>

    <v-btn :to="{ name: 'invite' }" exact color="primary">
      <!-- <v-icon>mdi-menu</v-icon> -->

      <span>Invite</span>
    </v-btn>
  </v-bottom-navigation>
</template>

<script lang="ts" setup>
import { useDisplay } from "vuetify/lib/framework.mjs";
const { smAndDown } = useDisplay();
</script>

<style scoped lang="scss">
#bottom-navigation {
  padding-bottom: env(safe-area-inset-top);
  height: calc(46px + env(safe-area-inset-top)) !important;
  border-top: 2px solid rgba(244, 193, 12, 1);

  .v-btn {
    font-size: 16px !important;
    text-transform: uppercase;
  }
}
</style>
