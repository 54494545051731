<template>
  <div class="container" ref="scrollComponent">
    <v-row>
      <v-col cols="12" md="12" class="py-0">
        <WalletConnectCard />
      </v-col>
      <v-col cols="12" md="12" class="py-0">
        <LatestTransactionsCarousel />
      </v-col>
      <v-col cols="12" md="12" class="py-1">
        <v-row class="mt-4">
          <v-col cols="12" md="8" lg="2" class="py-1">
            <div class="text-h4 pb-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="33"
                viewBox="0 0 32 33"
                fill="none"
              >
                <rect
                  x="4.92322"
                  y="28.0369"
                  width="22.1538"
                  height="2.46154"
                  fill="#812D31"
                />
                <rect
                  x="2.46143"
                  y="25.5754"
                  width="27.0769"
                  height="2.46154"
                  fill="#812D31"
                />
                <rect
                  x="2.46143"
                  y="5.88306"
                  width="27.0769"
                  height="2.46154"
                  fill="#812D31"
                />
                <rect
                  x="4.92322"
                  y="3.42154"
                  width="22.1538"
                  height="2.46154"
                  fill="#812D31"
                />
                <rect y="8.34464" width="32" height="17.2308" fill="#812D31" />
                <rect
                  x="7.38464"
                  y="3.42154"
                  width="2.46154"
                  height="27.0769"
                  fill="#BD614D"
                />
                <rect
                  x="7.38464"
                  y="5.88306"
                  width="17.2308"
                  height="22.1538"
                  fill="#E18A24"
                />
                <rect
                  x="4.92322"
                  y="5.88306"
                  width="2.46154"
                  height="22.1538"
                  fill="#BD614D"
                />
                <rect
                  x="4.92322"
                  y="10.8062"
                  width="2.46154"
                  height="14.7692"
                  fill="#F1CD46"
                />
                <rect
                  x="27.0768"
                  y="8.34464"
                  width="2.46154"
                  height="17.2308"
                  fill="#F1CD46"
                />
                <rect
                  x="24.6154"
                  y="5.88306"
                  width="2.46154"
                  height="22.1538"
                  fill="#F1CD46"
                />
                <rect
                  x="24.6154"
                  y="8.34464"
                  width="2.46154"
                  height="17.2308"
                  fill="#BD614D"
                />
                <rect
                  opacity="0.4"
                  x="22.1538"
                  y="10.8062"
                  width="2.46154"
                  height="17.2308"
                  fill="#BD614D"
                />
                <rect
                  opacity="0.4"
                  x="19.6923"
                  y="13.2677"
                  width="2.46154"
                  height="17.2308"
                  fill="#BD614D"
                />
                <rect
                  opacity="0.4"
                  x="17.2308"
                  y="15.7292"
                  width="2.46154"
                  height="14.7692"
                  fill="#BD614D"
                />
                <rect
                  opacity="0.4"
                  x="14.7692"
                  y="18.1907"
                  width="2.46154"
                  height="12.3077"
                  fill="#BD614D"
                />
                <rect
                  opacity="0.4"
                  x="12.3077"
                  y="20.6523"
                  width="2.46154"
                  height="9.84615"
                  fill="#BD614D"
                />
                <rect
                  opacity="0.4"
                  x="9.84619"
                  y="23.1138"
                  width="2.46154"
                  height="7.38461"
                  fill="#BD614D"
                />
                <rect
                  x="7.38464"
                  y="25.5754"
                  width="2.46154"
                  height="4.92308"
                  fill="#BD614D"
                />
                <rect
                  x="22.1538"
                  y="25.5754"
                  width="2.46154"
                  height="2.46154"
                  fill="#BD614D"
                />
                <rect
                  x="7.38464"
                  y="25.5754"
                  width="2.46154"
                  height="2.46154"
                  fill="#F1CD46"
                />
                <rect
                  x="22.1538"
                  y="5.88306"
                  width="2.46154"
                  height="2.46154"
                  fill="#BD614D"
                />
                <rect
                  x="2.46143"
                  y="8.34464"
                  width="2.46154"
                  height="17.2308"
                  fill="#BD614D"
                />
                <rect
                  x="12.3077"
                  y="3.42154"
                  width="12.3077"
                  height="2.46154"
                  fill="#F1CD46"
                />
                <rect
                  x="9.84619"
                  y="28.0369"
                  width="14.7692"
                  height="2.46154"
                  fill="#F1CD46"
                />
                <rect
                  x="4.92322"
                  y="8.34464"
                  width="2.46154"
                  height="2.46154"
                  fill="white"
                />
                <rect
                  x="7.38464"
                  y="5.88306"
                  width="2.46154"
                  height="2.46154"
                  fill="white"
                />
                <rect
                  x="9.84619"
                  y="3.42154"
                  width="2.46154"
                  height="2.46154"
                  fill="white"
                />
                <rect
                  x="7.38464"
                  y="0.959991"
                  width="17.2308"
                  height="2.46154"
                  fill="#812D31"
                />
                <rect
                  x="7.38464"
                  y="30.4984"
                  width="17.2308"
                  height="2.46154"
                  fill="#812D31"
                />
              </svg>
              <div style="width: 100%">Token List</div>
            </div>
          </v-col>
          <v-col cols="12" md="4" lg="2" class="py-1">
            <v-switch
              v-if="activeAccount"
              v-model="filterForCurrentAccount"
              label="Show Owned Only"
              color="primary"
            ></v-switch>
          </v-col>
          <v-col cols="12" md="3" lg="2" class="py-1">
            <v-menu>
              <template #activator="{ props }">
                <v-btn variant="outlined" v-bind="props" block height="42px">
                  Categories: {{ selectedCategory?.title }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in categoryOptions"
                  :key="index"
                  @click="updateQueryFilters({ category: item.value })"
                  :disabled="item.disabled"
                >
                  <v-list-item-title
                    :class="{
                      'text-primary': queryFilters.category == item.value,
                    }"
                  >
                    {{ item.title }}
                    <small class="pl-2" v-if="item.disabled"
                      >(Coming soon)</small
                    >
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="12" md="3" lg="2" class="py-1">
            <v-menu>
              <template #activator="{ props }">
                <v-btn variant="outlined" v-bind="props" block height="42px">
                  Sort: {{ activeSortOption?.title }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in sortOptions"
                  :key="index"
                  @click="
                    updateQueryFilters({
                      sort: item.value,
                    })
                  "
                >
                  <v-list-item-title
                    :class="{
                      'text-primary': queryFilters.sort == item.value,
                    }"
                    >{{ item.title }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="12" md="3" lg="2" class="py-1">
            <v-menu>
              <template #activator="{ props }">
                <v-btn variant="outlined" v-bind="props" block height="42px">
                  Order: {{ queryFilters.dir }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in sortDirectionOptions"
                  :key="index"
                  @click="updateQueryFilters({ dir: item.value })"
                >
                  <v-list-item-title
                    :class="{
                      'text-primary': queryFilters.dir == item.value,
                    }"
                    >{{ item.title }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="12" md="3" lg="2" class="py-1">
            <v-text-field
              v-model="search"
              label="Search for token"
              variant="outlined"
              append-inner-icon="mdi-magnify"
              density="compact"
              hide-details
              single-line
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="12" class="mt-2">
        <div v-if="isFetching" class="d-flex align-center justify-center">
          <div class="text-center">
            <v-progress-circular indeterminate color="primary" size="80" />
          </div>
        </div>
        <v-row>
          <v-alert
            v-if="
              (!data?.pages?.length || !data?.pages[0].items.length) &&
              !isFetching
            "
            type="info"
            outlined
            class="ma-3"
          >
            No token sales found
          </v-alert>
          <v-col v-if="!smAndDown" cols="12" md="12" class="pb-0 pt-8">
            <v-row class="table-title">
              <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                <div class="d-flex">
                  <div style="width: 87px">Rank</div>
                  <div>Token Name</div>
                </div>
              </v-col>
              <v-col cols="1" md="1" lg="1" class="text-right">
                Category
              </v-col>
              <v-col cols="1" md="2" lg="1" class="text-right"> Price </v-col>
              <v-col cols="1" md="2" lg="1" class="text-right">
                Market Cap
              </v-col>
              <v-col
                v-if="!mdAndDown"
                cols="3"
                md="2"
                lg="2"
                xl="1"
                class="px-1 text-center"
              >
                Contract Address
              </v-col>
              <v-col cols="1" class="px-1 text-center"> Performance </v-col>
            </v-row>
          </v-col>
          <template v-if="data?.pages">
            <template v-for="page in data.pages">
              <template v-if="page?.items">
                <v-col
                  v-for="token in page.items"
                  :key="token.sale_address"
                  cols="12"
                  md="12"
                  xl="12"
                  class="py-1"
                >
                  <MobileTokenSaleCard v-if="smAndDown" :token="token" />
                  <TokenSaleCard
                    v-else
                    :token="token"
                    :use-category-rank="queryFilters.category !== 'all'"
                  />
                </v-col>
              </template>
            </template>
          </template>
        </v-row>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn
          v-if="hasNextPage"
          @click="fetchNextPage"
          variant="outlined"
          rounded
          size="large"
          :loading="isFetching"
        >
          Load More
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref } from "vue";
import MobileTokenSaleCard from "@/components/Tokens/TokenSaleCard/MobileTokenSaleCard.vue";
import TokenSaleCard from "@/components/Tokens/TokenSaleCard/TokenSaleCard.vue";
import WalletConnectCard from "@/components/WalletConnect/WalletConnectCard.vue";
import LatestTransactionsCarousel from "@/components/LatestTransactionsCarousel.vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { TokensService } from "@/api/generated";
import { useInfiniteQuery } from "@tanstack/vue-query";
import WebSocketClient from "@/libs/WebSocketClient";
import { useNetwork } from "@/composables/useNetwork";
import { storeToRefs } from "pinia";
import { useAccounts } from "@/stores/accounts";
import { useRoute, useRouter } from "vue-router";

const { smAndDown, mdAndDown } = useDisplay();
const { activeNetworkId } = useNetwork();
const { activeAccount } = storeToRefs(useAccounts());
const route = useRoute();
const router = useRouter();

const queryFilters = computed(() => {
  return {
    sort: route.query.sort ?? "market_cap",
    dir: route.query.dir ?? "DESC",
    category: route.query.category ?? "all",
  };
});
function updateQueryFilters(query: Record<string, string>) {
  router.push({
    query: {
      ...route.query,
      ...query,
    },
  });
}

// Filters
const filterForCurrentAccount = ref(false);
const search = ref<string>();
const sortOptions = [
  {
    title: "Name",
    value: "name",
  },
  {
    title: "Market Cap",
    value: "market_cap",
  },
  {
    title: "Rank",
    value: "rank",
  },
  {
    title: "Creation Date",
    value: "created_at",
  },
];

const selectedCategory = computed(() => {
  return categoryOptions.find(
    (option) => option.value === queryFilters.value.category
  );
});
const categoryOptions = [
  {
    title: "All",
    value: "all",
  },
  {
    title: "Words",
    value: "word",
  },
  {
    title: "Numbers",
    value: "number",
  },
  {
    title: "词",
    value: "词",
    disabled: true,
  },
  {
    title: "Ideas",
    value: "ideas",
    disabled: true,
  },
  {
    title: "Images",
    value: "images",
    disabled: true,
  },
];
const activeSortOption = computed(() => {
  return sortOptions.find((option) => option.value === queryFilters.value.sort);
});
const sortDirectionOptions = [
  {
    title: "Ascending",
    value: "ASC",
  },
  {
    title: "Descending",
    value: "DESC",
  },
];
const scrollComponent = ref<HTMLElement>();

const mappedSortMode = computed(() => {
  if (queryFilters.value.category === "all") {
    return queryFilters.value.sort;
  }
  if (queryFilters.value.sort !== "rank") {
    return queryFilters.value.sort;
  }
  return "category_rank";
});

const { data, isFetching, fetchNextPage, hasNextPage, refetch } =
  useInfiniteQuery({
    initialPageParam: 1,
    queryFn: ({ pageParam = 1 }) =>
      TokensService.listAll({
        orderBy: mappedSortMode.value as any,
        orderDirection: queryFilters.value.dir as any,
        category: queryFilters.value.category as any,
        search: search.value,
        ownerAddress: filterForCurrentAccount.value
          ? (activeAccount.value as string)
          : undefined,
        limit: 50,
        page: pageParam,
      }),
    getNextPageParam: (lastPage: any, allPages, lastPageParam) => {
      if (lastPage?.meta?.currentPage === lastPage?.meta?.totalPages) {
        return undefined;
      }

      return lastPageParam + 1;
    },
    queryKey: [
      "TokensService.listAll",
      queryFilters,
      mappedSortMode,
      search,
      activeNetworkId,
      filterForCurrentAccount,
      activeAccount,
    ],
  });
const newTokenListenerSubscription = ref();
const refetchTimeout = ref<any>();
onMounted(() => {
  window.addEventListener("scroll", handleScroll);
  newTokenListenerSubscription.value = WebSocketClient.subscribeToNewTokenSales(
    (token: any) => {
      console.log("New token sale::", token);
      clearTimeout(refetchTimeout.value);
      refetchTimeout.value = setTimeout(() => {
        refetch();
      }, 4000);
    }
  );
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
  newTokenListenerSubscription.value?.();
});
const handleScroll = () => {
  let element: any = scrollComponent.value;
  if (
    element.getBoundingClientRect &&
    element.getBoundingClientRect().bottom < window.innerHeight
  ) {
    if (hasNextPage.value) {
      fetchNextPage();
    }
  }
};
</script>

<style lang="scss" scoped>
.table-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 14px */
  letter-spacing: 0.42px;
  opacity: 0.8;
  margin-bottom: -4px;
}
.text-h4 {
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 88.889% */
  letter-spacing: 1.44px !important;
  text-transform: uppercase;
  display: flex;
  width: 220px;
  padding: 8px 12px 8px 0px;
  align-items: center;
  gap: 8px;
}
</style>
