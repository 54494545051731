<template>
  <v-card
    v-if="token?.sale_address"
    block
    flat
    class="px-2 token-card"
    :to="`/sale/${token.sale_address}`"
  >
    <v-row align="center">
      <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="px-6 py-8">
        <div class="d-flex align-center">
          <div class="rank">
            #<b>{{ useCategoryRank ? token.category_rank : token.rank }}</b>
          </div>
          <div class="token-name">
            {{ token.symbol }}
          </div>
        </div>
      </v-col>
      <v-col cols="1" sm="1" md="1" lg="1" class="py-0 text-right">
        <v-chip class="text-h6">
          {{ token.category }}
          <v-progress-circular
            v-if="!token.category"
            indeterminate
            size="20"
            color="primary"
          />
        </v-chip>
      </v-col>
      <v-col cols="6" sm="3" md="2" lg="1" class="py-0 text-right">
        <PriceDataFormatter :price-data="tokenData.price_data" />
      </v-col>
      <v-col
        cols="6"
        sm="3"
        md="2"
        lg="1"
        class="py-0 token-market-cap text-right"
      >
        <PriceDataFormatter bignumber :price-data="tokenData.market_cap_data" />
      </v-col>
      <v-col cols="3" md="2" lg="2" xl="1" class="px-0 text-center">
        <AddressChip :address="token.sale_address" link />
      </v-col>
      <v-col cols="12" sm="9" md="1">
        <TokenLineChart
          :sale-address="token.address"
          chart-type="line"
          :height="60"
        />
      </v-col>
      <v-col cols="12" sm="3" md="2" v-if="!mdAndDown">
        <v-btn variant="outlined" block :to="`/sale/${token.sale_address}`">
          VIEW TOKEN
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts" setup>
import { defineProps } from "vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import PriceDataFormatter from "@/components/Common/Pricing/PriceDataFormatter.vue";
import TokenLineChart from "@/components/Charts/TokenLineChart.vue";
import AddressChip from "@/components/Common/AddressChip.vue";
import { TokenDto } from "@/api/generated";
import { useLiveTokenData } from "@/composables/useLiveTokenData";
const { mdAndDown } = useDisplay();

const props = defineProps<{
  token: TokenDto;
  useCategoryRank?: boolean;
}>();
const { tokenData } = useLiveTokenData({
  token: props.token,
});
</script>

<style lang="scss" scoped>
@import "@/styles/utils.scss";

.rank {
  font-size: 25px;
  opacity: 0.3;
  width: 70px;
}

.token-name {
  @include primary-font;
  font-size: 36px;
  letter-spacing: 1.44px;
  font-weight: 600;
  font-style: normal;
  line-height: 100%;
}

.token-price {
  font-size: 16px;
}

.token-market-cap {
  font-size: 16px;
  font-weight: 500;

  .fiat {
    opacity: 0.8;
    font-weight: 400;
  }
}
.v-btn {
  width: 162px;
  &--block {
    min-width: auto;
    text-align: right;
    margin: auto;
  }
}
@media screen and (max-width: 1479px) {
  .rank {
    font-size: 18px;
  }
  .token-name {
    font-size: 24px;
  }
  .token-market-cap {
    font-size: 14px;
  }
}

.v-theme--darkTokaen .token-card {
  background: #212121;
}
.v-theme--tokaen .token-card {
  background: #ffffff;
}

.token-card:hover {
  .rank {
    opacity: 0.8;
  }

  .token-name {
    color: #f4c10c;
    margin-left: 5px;
    transition: margin-left 0.2s;
  }
}
</style>
