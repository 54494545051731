import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["copied"]
const _hoisted_2 = {
  ref: "canvas",
  class: "canvas"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    copied: _ctx.externalCopied,
    class: "qr-code",
    style: _normalizeStyle({
      width: `${_ctx.size}px`,
      height: `${_ctx.size}px`,
    })
  }, [
    _createElementVNode("div", _hoisted_2, null, 512)
  ], 12, _hoisted_1))
}