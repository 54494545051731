import "moment-timezone";
import { createApp } from "vue";
import { VueQueryPlugin } from "@tanstack/vue-query";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { PiniaSharedState } from "pinia-shared-state";
import { IS_FRAMED_AEPP } from "./utils/constants";
import VueNumberFormat from "@coders-tm/vue-number-format";

loadFonts();

const app = createApp(App);

const pinia = createPinia();
if (!IS_FRAMED_AEPP) {
  pinia.use(piniaPluginPersistedstate);

  pinia.use(
    PiniaSharedState({
      enable: false,
      initialize: false,
    })
  );
}

app.use(VueNumberFormat, { precision: 4 });
app.use(VueQueryPlugin);
app.use(pinia);
app.use(router);
app.use(vuetify);

app.mount("#app");
