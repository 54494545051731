/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TransactionHistoricalService {
    /**
     * @returns any
     * @throws ApiError
     */
    public static findByAddress({
        address,
        interval,
        startDate,
        endDate,
        convertTo,
        mode,
    }: {
        /**
         * Token address or name
         */
        address: string,
        /**
         * Interval in seconds
         */
        interval?: number,
        startDate?: string,
        endDate?: string,
        convertTo?: 'ae' | 'usd' | 'eur' | 'aud' | 'brl' | 'cad' | 'chf' | 'gbp' | 'xau',
        mode?: 'normal' | 'aggregated',
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tokens/{address}/transactions',
            path: {
                'address': address,
            },
            query: {
                'interval': interval,
                'start_date': startDate,
                'end_date': endDate,
                'convertTo': convertTo,
                'mode': mode,
            },
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    public static getForPreview({
        address,
    }: {
        /**
         * Token address or name
         */
        address: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tokens/preview/{address}',
            path: {
                'address': address,
            },
        });
    }
}
