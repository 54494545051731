<template>
  <div v-if="latestTransactions?.length">
    <div class="text-h4 pb-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
      >
        <rect
          x="4.92322"
          y="28.0369"
          width="22.1538"
          height="2.46154"
          fill="#812D31"
        />
        <rect
          x="2.46143"
          y="25.5754"
          width="27.0769"
          height="2.46154"
          fill="#812D31"
        />
        <rect
          x="2.46143"
          y="5.88306"
          width="27.0769"
          height="2.46154"
          fill="#812D31"
        />
        <rect
          x="4.92322"
          y="3.42154"
          width="22.1538"
          height="2.46154"
          fill="#812D31"
        />
        <rect y="8.34464" width="32" height="17.2308" fill="#812D31" />
        <rect
          x="7.38464"
          y="3.42154"
          width="2.46154"
          height="27.0769"
          fill="#BD614D"
        />
        <rect
          x="7.38464"
          y="5.88306"
          width="17.2308"
          height="22.1538"
          fill="#E18A24"
        />
        <rect
          x="4.92322"
          y="5.88306"
          width="2.46154"
          height="22.1538"
          fill="#BD614D"
        />
        <rect
          x="4.92322"
          y="10.8062"
          width="2.46154"
          height="14.7692"
          fill="#F1CD46"
        />
        <rect
          x="27.0768"
          y="8.34464"
          width="2.46154"
          height="17.2308"
          fill="#F1CD46"
        />
        <rect
          x="24.6154"
          y="5.88306"
          width="2.46154"
          height="22.1538"
          fill="#F1CD46"
        />
        <rect
          x="24.6154"
          y="8.34464"
          width="2.46154"
          height="17.2308"
          fill="#BD614D"
        />
        <rect
          opacity="0.4"
          x="22.1538"
          y="10.8062"
          width="2.46154"
          height="17.2308"
          fill="#BD614D"
        />
        <rect
          opacity="0.4"
          x="19.6923"
          y="13.2677"
          width="2.46154"
          height="17.2308"
          fill="#BD614D"
        />
        <rect
          opacity="0.4"
          x="17.2308"
          y="15.7292"
          width="2.46154"
          height="14.7692"
          fill="#BD614D"
        />
        <rect
          opacity="0.4"
          x="14.7692"
          y="18.1907"
          width="2.46154"
          height="12.3077"
          fill="#BD614D"
        />
        <rect
          opacity="0.4"
          x="12.3077"
          y="20.6523"
          width="2.46154"
          height="9.84615"
          fill="#BD614D"
        />
        <rect
          opacity="0.4"
          x="9.84619"
          y="23.1138"
          width="2.46154"
          height="7.38461"
          fill="#BD614D"
        />
        <rect
          x="7.38464"
          y="25.5754"
          width="2.46154"
          height="4.92308"
          fill="#BD614D"
        />
        <rect
          x="22.1538"
          y="25.5754"
          width="2.46154"
          height="2.46154"
          fill="#BD614D"
        />
        <rect
          x="7.38464"
          y="25.5754"
          width="2.46154"
          height="2.46154"
          fill="#F1CD46"
        />
        <rect
          x="22.1538"
          y="5.88306"
          width="2.46154"
          height="2.46154"
          fill="#BD614D"
        />
        <rect
          x="2.46143"
          y="8.34464"
          width="2.46154"
          height="17.2308"
          fill="#BD614D"
        />
        <rect
          x="12.3077"
          y="3.42154"
          width="12.3077"
          height="2.46154"
          fill="#F1CD46"
        />
        <rect
          x="9.84619"
          y="28.0369"
          width="14.7692"
          height="2.46154"
          fill="#F1CD46"
        />
        <rect
          x="4.92322"
          y="8.34464"
          width="2.46154"
          height="2.46154"
          fill="white"
        />
        <rect
          x="7.38464"
          y="5.88306"
          width="2.46154"
          height="2.46154"
          fill="white"
        />
        <rect
          x="9.84619"
          y="3.42154"
          width="2.46154"
          height="2.46154"
          fill="white"
        />
        <rect
          x="7.38464"
          y="0.959991"
          width="17.2308"
          height="2.46154"
          fill="#812D31"
        />
        <rect
          x="7.38464"
          y="30.4984"
          width="17.2308"
          height="2.46154"
          fill="#812D31"
        />
      </svg>
      <div>Latest Trades</div>
    </div>
    <Carousel
      ref="carouselRef"
      :options="{ currentPage: 0 }"
      snap-align="start"
      :autoplay="1000"
      pause-autoplay-on-hover
      :items-to-show="4"
      :breakpoints="{
        1920: { itemsToShow: 4 },
        1280: { itemsToShow: 3 },
        600: { itemsToShow: 2 },
        100: { itemsToShow: 1 },
      }"
    >
      <Slide v-for="transaction in latestTransactions" :key="transaction.hash">
        <v-card
          id="latestTransactionRef"
          ref="latestTransactionRef"
          variant="outlined"
          block
          style="width: 100%"
          class="mr-2 border-md pa-2 d-flex align-center justify-space-between latest-trades-item"
        >
          <div class="d-flex align-center">
            <AddressChip :address="transaction.tx.callerId" />

            <span class="mx-1 is-grey">
              {{
                transaction.tx.function === TX_FUNCTIONS.sell
                  ? "sold"
                  : "bought"
              }}</span
            ><strong class="amount"> {AMOUNT} </strong>
            <span class="mx-1 is-grey">of</span>
            <strong class="pixelated"> {symbol} </strong>
          </div>

          <v-chip :href="prepareUrlForHash(transaction.hash)" target="_blank">
            <v-avatar>
              <v-icon>mdi-open-in-new</v-icon>
            </v-avatar>
          </v-chip>
        </v-card>
      </Slide>
    </Carousel>
  </div>
</template>

<script lang="ts" setup>
import "vue3-carousel/dist/carousel.css";
// https://ismail9k.github.io/vue3-carousel/examples.html
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import AddressChip from "@/components/Common/AddressChip.vue";

import { ref } from "vue";
import type { ITransaction } from "@/utils/types";
const latestTransactionRef = ref<HTMLElement>();
import { TX_FUNCTIONS } from "@/utils/constants";
import { useAeScan } from "@/composables/useAeScan";

const { prepareUrlForHash } = useAeScan();

const carouselRef = ref(null);

const latestTransactions = ref<ITransaction[]>([]);
</script>

<style lang="scss" scoped>
@import "@/styles/utils.scss";
.text-h4 {
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 88.889% */
  letter-spacing: 1.44px !important;
  text-transform: uppercase;
  display: flex;
  padding: 8px 12px 8px 0px;
  margin-bottom: 12px;
  align-items: center;
  gap: 8px;
}
.latest-trades-item {
  border: 0px !important;
  border-radius: 2px;
}
.v-theme--darkTokaen .latest-trades-item {
  background: #212121;
}
.v-theme--tokaen .latest-trades-item {
  background: #ffffff;
}
.amount {
  font-weight: 500;
}
.is-grey {
  opacity: 0.8;
}
.pixelated {
  @include primary-font;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 16px */
  letter-spacing: 0.72px !important;
}
</style>
