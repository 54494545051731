import { useAppStore } from "@/stores/app";
import {
  ChartOptions,
  ColorType,
  CrosshairMode,
  DeepPartial,
  IChartApi,
  createChart,
} from "lightweight-charts";
import { storeToRefs } from "pinia";
import { onMounted, onUnmounted, ref, watch } from "vue";

export interface UseChartProps {
  onChartReady?: (chart: IChartApi) => void;
  height?: number;
  chartOptions?: DeepPartial<ChartOptions>;
}

export function useChart(
  { onChartReady, height, chartOptions }: UseChartProps = {
    height: 400,
    chartOptions: {
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
      },
    },
  }
) {
  const chartContainer = ref<HTMLElement>();
  const chart = ref<IChartApi>();
  const { darkMode } = storeToRefs(useAppStore());

  function resizeHandler() {
    if (!chart.value || !chartContainer.value) return;
    const dimensions = chartContainer.value?.getBoundingClientRect();
    chart.value.resize(dimensions.width, height || 400);
  }

  function initChart() {
    if (!chartContainer.value || chart.value) return;
    const _chartOptions: DeepPartial<ChartOptions> = {
      layout: {
        textColor: darkMode.value ? "white" : "black",
        background: { color: "transparent", type: ColorType.Solid },
      },
      grid: {
        vertLines: {
          color: "rgba(255,255,255, 0.08)",
        },
        horzLines: {
          color: "rgba(255,255,255, 0.08)",
        },
      },
      // https://tradingview.github.io/lightweight-charts/tutorials/customization/crosshair#styling-the-crosshair
      crosshair: {
        mode: CrosshairMode.Magnet,
        // Vertical crosshair line (showing Date in Label)
        vertLine: {
          color: "#F4C10C",
          labelBackgroundColor: "#F4C10C",
        },
        // Horizontal crosshair line (showing Price in Label)
        horzLine: {
          color: "#F4C10C",
          labelBackgroundColor: "#F4C10C",
        },
      },
      height,
      width: chartContainer.value.offsetWidth,
      ...chartOptions,
    };

    const _chart = createChart(chartContainer.value, _chartOptions);

    onChartReady?.(_chart);

    chart.value = _chart;
  }

  watch(
    darkMode,
    () => {
      if (!chart.value) return;
      chart.value.applyOptions({
        layout: {
          textColor: darkMode.value ? "white" : "black",
        },
      });
    },
    {
      immediate: true,
    }
  );

  onMounted(() => {
    initChart();
    window.addEventListener("resize", () => resizeHandler());
  });

  onUnmounted(() => {
    if (chart.value) {
      chart.value.remove();
      chart.value = undefined;
    }
    window.removeEventListener("resize", resizeHandler);
  });

  return {
    chartContainer,
    chart,
  };
}
