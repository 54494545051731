import { watch, type WatchSource } from "vue";
import { defer } from "lodash-es";
import {
  AE_AENS_DOMAIN,
  AE_HASH_PREFIXES_ALLOWED,
  HASH_REGEX,
} from "./constants";

/**
 * Watch for the getter to be truthy with the use of the compositionApi.
 */
export function watchUntilTruthy<T>(
  getter: WatchSource<T>
): Promise<NonNullable<T>> {
  return new Promise((resolve) => {
    const unwatch = watch(
      getter,
      (value) => {
        if (value) {
          resolve(value as NonNullable<T>);
          defer(() => unwatch());
        }
      },
      { immediate: true }
    );
  });
}

export async function fetchJson<T = any>(
  url: string,
  options?: RequestInit
): Promise<T | null> {
  const response = await fetch(url, options);
  if (response.status === 204) {
    return null;
  }
  return response.json();
}

export const panic = <T>(e: unknown): T => {
  throw e;
};

export function validateHash(fullHash?: string) {
  type HashPrefix = (typeof AE_HASH_PREFIXES_ALLOWED)[number];
  const isName = !!fullHash?.endsWith(AE_AENS_DOMAIN);
  let valid = false;
  let prefix: HashPrefix | null = null;
  let hash = null;

  if (fullHash) {
    [prefix, hash] = fullHash.split("_") as [HashPrefix, string];
    valid =
      (AE_HASH_PREFIXES_ALLOWED.includes(prefix) && HASH_REGEX.test(hash)) ||
      isName;
  }

  return {
    valid,
    isName,
    prefix,
    hash,
  };
}
