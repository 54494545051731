<template>
  <v-btn
    v-if="!activeAccount"
    color="primary"
    variant="outlined"
    data-cy="connect-wallet"
    :loading="scanningForWallets"
    @click="connectWallet()"
  >
    <img
      :src="superheroWalletIcon"
      style="height: 20px"
      :class="{ 'mr-2': !smAndDown }"
    />
    <span v-if="!(smAndDown && !hideTextOnSm)">{{
      connectWalletText ?? "Connect Wallet"
    }}</span>
  </v-btn>
</template>

<script lang="ts" setup>
import { defineProps } from "vue";
import { useWalletConnect } from "@/composables/useWalletConnect";
import { storeToRefs } from "pinia";
import { useAccounts } from "@/stores/accounts";
import superheroWalletIcon from "@/assets/icons/sh-wallet.svg";
import { useDisplay } from "vuetify/lib/framework.mjs";
const { smAndDown } = useDisplay();
const { connectWallet, scanningForWallets } = useWalletConnect();
const { activeAccount } = storeToRefs(useAccounts());
const props = defineProps<{
  hideTextOnSm?: boolean;
  connectWalletText?: string;
}>();
</script>
