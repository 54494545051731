import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { useAppStore } from "@/stores/app";

export default /*@__PURE__*/_defineComponent({
  __name: 'ToggleDarkMode',
  setup(__props) {

const { toggleDarkMode } = useAppStore();

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return false
    ? (_openBlock(), _createBlock(_component_v_btn, {
        key: 0,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(toggleDarkMode)())),
        variant: "text"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, null, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("mdi-theme-light-dark")
            ])),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})