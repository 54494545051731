import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  class: "d-flex align-center justify-center text-white",
  style: {"position":"absolute","top":"0","left":"0","right":"0","bottom":"0","font-weight":"900"}
}

import coinIcon from "@/assets/icons/coin-black.svg";
import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BlackCoinBullet',
  props: {
    size: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({
      position: 'relative',
      width: `${_ctx.size ?? 50}px`,
      height: `${_ctx.size ?? 50}px`,
    })
  }, [
    _createElementVNode("img", {
      src: _unref(coinIcon),
      style: _normalizeStyle({
        width: `${_ctx.size ?? 48}px`,
        height: `${_ctx.size ?? 48}px`,
      })
    }, null, 12, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 4))
}
}

})