import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { key: 3 }
const _hoisted_2 = {
  key: 0,
  class: "fiat"
}

import { useCurrencies } from "@/composables/useCurrencies";
import { Decimal } from "@/libs/decimal";
import { computed, PropType, ref, watch } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PriceFormatter',
  props: {
  aePrice: {
    type: Object as PropType<Decimal>,
    required: true,
  },
  priceLoading: {
    type: Boolean,
    default: false,
  },
  fiatPrice: {
    type: Object as PropType<Decimal>,
    required: true,
  },
  hideFiatPrice: {
    type: Boolean,
    default: false,
  },
  symbol: {
    type: String,
    default: "AE",
  },
  hideSymbol: {
    type: Boolean,
    default: false,
  },
  row: {
    type: Boolean,
    default: false,
  },
  watchPrice: {
    type: Boolean,
    default: true,
  },
},
  setup(__props) {

const props = __props;

const { formatCurrency } = useCurrencies();

const formattedFiat = computed(() => {
  return formatCurrency(props.fiatPrice);
});

const priceJustIncreased = ref(false);
const priceJustDecreased = ref(false);
watch(
  () => props.aePrice,
  (value, prev) => {
    if (!value || !prev || !props.watchPrice) return;
    if (value.gte(prev)) {
      priceJustIncreased.value = true;
      setTimeout(() => {
        priceJustIncreased.value = false;
      }, 500);
    } else {
      priceJustDecreased.value = true;
      setTimeout(() => {
        priceJustDecreased.value = false;
      }, 500);
    }
  }
);

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({
    class: [
      'price-formatter',
      {
        'text-red': priceJustDecreased.value,
        'text-green': priceJustIncreased.value,
        'd-flex align-center ga-1': __props.row,
      },
    ]
  }, _ctx.$attrs), [
    _createElementVNode("div", null, [
      (priceJustIncreased.value)
        ? (_openBlock(), _createBlock(_component_v_icon, { key: 0 }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("mdi-trending-up")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      (priceJustDecreased.value)
        ? (_openBlock(), _createBlock(_component_v_icon, { key: 1 }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("mdi-trending-down")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(__props.aePrice?.prettify()) + " ", 1),
      (__props.priceLoading)
        ? (_openBlock(), _createBlock(_component_v_progress_circular, {
            key: 2,
            indeterminate: "",
            size: "15"
          }))
        : (!__props.hideSymbol)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(__props.symbol), 1))
          : _createCommentVNode("", true)
    ]),
    (!__props.hideFiatPrice)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(formattedFiat.value), 1))
      : _createCommentVNode("", true)
  ], 16))
}
}

})