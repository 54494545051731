<template>
  <div
    :class="[
      'price-formatter',
      {
        'text-red': priceJustDecreased,
        'text-green': priceJustIncreased,
        'd-flex align-center ga-1': row,
      },
    ]"
    v-bind="$attrs"
  >
    <div>
      <v-icon v-if="priceJustIncreased">mdi-trending-up</v-icon>
      <v-icon v-if="priceJustDecreased">mdi-trending-down</v-icon>
      {{ aePrice?.prettify() }}
      <v-progress-circular v-if="priceLoading" indeterminate size="15" />
      <span v-else-if="!hideSymbol">{{ symbol }}</span>
    </div>
    <span v-if="!hideFiatPrice" class="fiat">{{ formattedFiat }}</span>
  </div>
</template>

<script lang="ts" setup>
import { useCurrencies } from "@/composables/useCurrencies";
import { Decimal } from "@/libs/decimal";
import { computed, defineProps, PropType, ref, watch } from "vue";

const props = defineProps({
  aePrice: {
    type: Object as PropType<Decimal>,
    required: true,
  },
  priceLoading: {
    type: Boolean,
    default: false,
  },
  fiatPrice: {
    type: Object as PropType<Decimal>,
    required: true,
  },
  hideFiatPrice: {
    type: Boolean,
    default: false,
  },
  symbol: {
    type: String,
    default: "AE",
  },
  hideSymbol: {
    type: Boolean,
    default: false,
  },
  row: {
    type: Boolean,
    default: false,
  },
  watchPrice: {
    type: Boolean,
    default: true,
  },
});

const { formatCurrency } = useCurrencies();

const formattedFiat = computed(() => {
  return formatCurrency(props.fiatPrice);
});

const priceJustIncreased = ref(false);
const priceJustDecreased = ref(false);
watch(
  () => props.aePrice,
  (value, prev) => {
    if (!value || !prev || !props.watchPrice) return;
    if (value.gte(prev)) {
      priceJustIncreased.value = true;
      setTimeout(() => {
        priceJustIncreased.value = false;
      }, 500);
    } else {
      priceJustDecreased.value = true;
      setTimeout(() => {
        priceJustDecreased.value = false;
      }, 500);
    }
  }
);
</script>

<style lang="scss" scoped>
.price-formatter {
  font-weight: 500;

  .fiat {
    opacity: 0.8;
    font-weight: 400;
  }
}
</style>
