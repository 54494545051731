import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-flex align-center justify-center",
  style: {"height":"100vh","width":"100vw"}
}
const _hoisted_2 = { class: "text-center" }

import { useTxQueueStore } from "@/stores/txQueue";
import { storeToRefs } from "pinia";
import { watch } from "vue";
import { useRoute } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionQueue',
  setup(__props) {

const { transactionsQueue } = storeToRefs(useTxQueueStore());

const route = useRoute();

watch(
  route,
  (_route) => {
    if (_route.params.id) {
      console.log("Transaction", _route.query.transaction, _route.params.id);
      transactionsQueue.value = {
        ...transactionsQueue.value,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [_route.params.id]: {
          ..._route.query,
        },
      };
      // close current tab
      setTimeout(() => {
        window.close();
      }, 200);
    }
  },
  {
    deep: true,
    immediate: true,
  }
);

return (_ctx: any,_cache: any) => {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_progress_circular, {
        indeterminate: "",
        color: "primary",
        size: "80"
      })
    ])
  ]))
}
}

})