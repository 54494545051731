<template>
  <v-card
    v-if="!activeAccount && !invitationCode"
    class="custom-card my-4 banner"
  >
    <v-row>
      <v-col cols="12" md="8" xl="11">
        <v-card-title class="text-h3">
          Connect your
          <span class="text-primary text-decoration-underline"
            >Superhero wallet</span
          >
          to get started
        </v-card-title>

        <v-card-text>
          Create and manage token-sales effortlessly on the æternity blockchain
          with fair, dynamic pricing via bonding curve. <br />
          Launch tokens, attract buyers, raise funds, earn through referrals,
          and build thriving token-gated communities for businesses, projects,
          DAOs, or even meme coins.
        </v-card-text>

        <v-card-text class="d-flex flex-wrap ga-4">
          <WalletConnect :block="smAndDown" />
          <HowItWorksDialog />
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts" setup>
import WalletConnect from "./WalletConnect.vue";
import HowItWorksDialog from "@/components/Dialogs/HowItWorksDialog.vue";
// import AppLogo from "@/components/Common/AppLogo.vue";
import { storeToRefs } from "pinia";
import { useAccounts } from "@/stores/accounts";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { useInvitation } from "@/composables/useInvitation";

const { activeAccount } = storeToRefs(useAccounts());
const { smAndDown } = useDisplay();

const { invitationCode } = useInvitation();
</script>

<style lang="scss" scoped>
.v-theme--darkTokaen .banner {
  background: #212121;
}
.v-theme--tokaen .banner {
  background: #ffffff;
  box-shadow: none;
}
.text-h4 {
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1.44px !important;
}
</style>
