import { Encoded, Encoding } from "@aeternity/aepp-sdk";
import type {
  CurrencyCode,
  ICurrency,
  INetwork,
  IRoomFactoryContracts,
  IToken,
} from "./types";

export const AETERNITY_CONTRACT_ID = "aeternity";
export const AETERNITY_SYMBOL = "AE";
export const AETERNITY_COIN_ID = "aeternity";
export const AETERNITY_COIN_SYMBOL = "AE Coin";
export const AETERNITY_COIN_NAME = "Aeternity";
export const AETERNITY_COIN_PRECISION = 18; // Amount of decimals

export const IS_FRAMED_AEPP = window.parent !== window;
export const IS_MOBILE = window.navigator.userAgent.includes("Mobi");
export const IS_SAFARI =
  /Mozilla\/5.0 \((Macintosh|iPad|iPhone|iPod); [\s\S]+?\) AppleWebKit\/\S+ \(KHTML, like Gecko\)( (Version|Safari|Mobile)\/\S+)+/.test(
    navigator.userAgent
  );

export const AETERNITY_TOKEN_BASE_DATA: Partial<IToken> = {
  address: AETERNITY_CONTRACT_ID,
  decimals: AETERNITY_COIN_PRECISION,
  name: AETERNITY_COIN_NAME,
  symbol: AETERNITY_SYMBOL,
};

export const AE_SYMBOL = "AE";

/**
 * Default `networkId` values returned by the Node after establishing the connection.
 * Nodes returns different values when connecting to the Hyperchains.
 */
export const NETWORK_ID_MAINNET = "ae_mainnet";
export const NETWORK_ID_TESTNET = "ae_uat";

// export const NETWORK_MAINNET: INetwork = {
//   url: "https://mainnet.aeternity.io",
//   networkId: NETWORK_ID_MAINNET,
//   apiUrl: process.env.VUE_APP_MAINNET_API || "https://mainnet.api.tokaen.org",
//   websocketUrl:
//     process.env.VUE_APP_MAINNET_WS || "https://mainnet.api.tokaen.org",
//   middlewareUrl: "https://mainnet.aeternity.io/mdw",
//   explorerUrl: "https://aescan.io",
//   compilerUrl: "https://v7.compiler.aepps.com",
//   name: "Mainnet",
// };

export const NETWORK_TESTNET: INetwork = {
  url: "https://testnet.aeternity.io",
  networkId: NETWORK_ID_TESTNET,
  apiUrl: process.env.VUE_APP_TESTNET_API || "https://testnet.api.tokaen.org",
  websocketUrl:
    process.env.VUE_APP_TESTNET_WS || "https://testnet.api.tokaen.org",
  middlewareUrl: "https://testnet.aeternity.io/mdw",
  explorerUrl: "https://testnet.aescan.io",
  compilerUrl: "https://v7.compiler.aepps.com",
  name: "Testnet",
};

export const NETWORKS = {
  // [NETWORK_ID_MAINNET]: NETWORK_MAINNET,
  [NETWORK_ID_TESTNET]: NETWORK_TESTNET,
};

/**
 * Token sale contracts addresses for different networks.
 * The last contract in the array is the default one.
 */
export const COMMUNITY_FACTORY_CONTRACTS: IRoomFactoryContracts = {
  // [NETWORK_ID_MAINNET]: [
  //   {
  //     contractId: "ct_2YWMMhFzsQWSNXsBTFZD6A6FHtgtNVbfT2ZtaUpDGpmKPpRXhJ",
  //     description: "Token Gating Contract (Mainnet)",
  //   },
  // ],
  [NETWORK_ID_TESTNET]: [
    {
      contractId: "ct_2P3DfJo3Bi4iMCmvQRoHfTdiQyiu35ax7FCm8nrdSQ2uMfn2HU",
      description: "Token Gating Contract (Testnet:v1.0.2)",
    },
    {
      contractId: "ct_yqXZuGX4J8qsMRoVzYF31Je9Tdwo5F7AFpPo1NRni9EXNEdFX",
      description: "Token Gating Contract (Testnet:v1.0.3)",
    },
    {
      contractId: "ct_HqknMBru4eGT4kQS7yt2M9fUrwBDFcWV2Dbq2Zrim1xNzHi1D",
      description: "Token Gating Contract (Testnet:v1.0.4)",
    },
  ],
};

export const DEFAULT_CURRENCY_CODE: CurrencyCode = "usd";

export const CURRENCIES: ICurrency[] = [
  {
    name: "United States Dollar",
    code: "usd",
    symbol: "$",
  },
  {
    name: "Euro",
    code: "eur",
    symbol: "€",
  },
  {
    name: "Australia Dollar",
    code: "aud",
    symbol: "AU$",
  },
  {
    name: "Brasil Real",
    code: "brl",
    symbol: "R$",
  },
  {
    name: "Canada Dollar",
    code: "cad",
    symbol: "CA$",
  },
  {
    name: "Swiss Franc",
    code: "chf",
    symbol: "CHF",
  },
  {
    name: "United Kingdom Pound",
    code: "gbp",
    symbol: "£",
  },
  {
    name: "Gold Ounce",
    code: "xau",
    symbol: "XAU",
  },
];

export const WEB_SOCKET_CHANNELS = {
  TokenCreated: "token-created",
  TokenUpdated: "token-updated",
  TokenTransaction: "token-transaction",
  TokenHistory: "token-history",
};

export const WEB_SOCKET_RECONNECT_TIMEOUT = 1000;

export const PUSH_NOTIFICATION_AUTO_CLOSE_TIMEOUT = 10000;

export const TX_FUNCTIONS = {
  buy: "buy",
  sell: "sell",
  create_community: "create_community",
} as const;

export enum CreateOptionsType {
  FIXED_PRICE = "FIXED_PRICE",
  BONDING_CURVE = "BONDING_CURVE",
  CUSTOM_OPTIONS = "CUSTOM_OPTIONS",
}

export const HASH_REGEX = /^[1-9A-HJ-NP-Za-km-z]{48,50}$/;

export const AE_HASH_PREFIXES_ALLOWED = [
  Encoding.AccountAddress,
  Encoding.Channel,
  Encoding.ContractAddress,
  Encoding.Name,
  Encoding.OracleAddress,
  Encoding.TxHash,
] as const;

export const AE_AENS_DOMAIN = ".chain";
