import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import largeLightLogo from "@/assets/logo/token-org-logo.svg";
import largeDarkLogo from "@/assets/logo/token-org-logo-dark.svg";
import smallLightLogo from "@/assets/logo/token-org-logo-small.svg";
import smallDarkLogo from "@/assets/logo/token-org-logo-small-dark.svg";
import verticalLightLogo from "@/assets/logo/token-org-logo.svg";
import verticalDarkLogo from "@/assets/logo/token-org-logo-dark.svg";
import { storeToRefs } from "pinia";
import { useAppStore } from "@/stores/app";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppLogo',
  props: {
    sm: { type: Boolean },
    vertical: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const { darkMode } = storeToRefs(useAppStore());

const logo = computed(() => {
  const largeLogo = darkMode.value ? largeDarkLogo : largeLightLogo;
  const smallLogo = darkMode.value ? smallDarkLogo : smallLightLogo;
  const verticalLogo = darkMode.value ? verticalLightLogo : verticalDarkLogo;

  if (props.sm) {
    return smallLogo;
  }

  if (props.vertical) {
    return verticalLogo;
  }

  return largeLogo;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("img", {
    src: logo.value,
    style: {"max-width":"100%","height":"100%"}
  }, null, 8, _hoisted_1))
}
}

})