import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import WalletConnect from "./WalletConnect.vue";
import HowItWorksDialog from "@/components/Dialogs/HowItWorksDialog.vue";
// import AppLogo from "@/components/Common/AppLogo.vue";
import { storeToRefs } from "pinia";
import { useAccounts } from "@/stores/accounts";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { useInvitation } from "@/composables/useInvitation";


export default /*@__PURE__*/_defineComponent({
  __name: 'WalletConnectCard',
  setup(__props) {

const { activeAccount } = storeToRefs(useAccounts());
const { smAndDown } = useDisplay();

const { invitationCode } = useInvitation();

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!

  return (!_unref(activeAccount) && !_unref(invitationCode))
    ? (_openBlock(), _createBlock(_component_v_card, {
        key: 0,
        class: "custom-card my-4 banner"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "8",
                xl: "11"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, { class: "text-h3" }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode(" Connect your "),
                      _createElementVNode("span", { class: "text-primary text-decoration-underline" }, "Superhero wallet", -1),
                      _createTextVNode(" to get started ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode(" Create and manage token-sales effortlessly on the æternity blockchain with fair, dynamic pricing via bonding curve. "),
                      _createElementVNode("br", null, null, -1),
                      _createTextVNode(" Launch tokens, attract buyers, raise funds, earn through referrals, and build thriving token-gated communities for businesses, projects, DAOs, or even meme coins. ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, { class: "d-flex flex-wrap ga-4" }, {
                    default: _withCtx(() => [
                      _createVNode(WalletConnect, { block: _unref(smAndDown) }, null, 8, ["block"]),
                      _createVNode(HowItWorksDialog)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})