<template>
  <CountDown v-if="SHOW_COUNTDOWN" />
  <div
    v-else-if="loadingApp"
    class="d-flex align-center justify-center"
    style="height: 100vh; width: 100vw"
  >
    <div class="text-center">
      <v-progress-circular indeterminate color="primary" size="80" />
    </div>
  </div>
  <v-app v-else :theme="darkMode ? 'darkTokaen' : 'tokaen'">
    <AppHeader />
    <v-main
      :class="{
        'px-4 py-0': !smAndDown,
        'px-3 py-0': smAndDown,
      }"
      style="margin-bottom: 56px; width: 100%"
      :style="{ 'margin-top': '80px' }"
    >
      <CollectInvitationLinkCard />
      <router-view />
    </v-main>
    <BottomNavigation />
  </v-app>
</template>

<script lang="ts" setup>
import AppHeader from "@/components/Header/AppHeader.vue";
import BottomNavigation from "@/components/BottomNavigation.vue";
import CollectInvitationLinkCard from "@/components/Invitation/CollectInvitationLinkCard.vue";
import CountDown from "@/components/CountDown.vue";

import { storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { useAeppSdk } from "./composables/aeppSdk";
import { useWalletConnect } from "./composables/useWalletConnect";
import WebSocketClient from "./libs/WebSocketClient";
import { useAppStore } from "./stores/app";
import { useNetworkStore } from "./stores/network";
const { darkMode } = storeToRefs(useAppStore());
import { useDisplay } from "vuetify/lib/framework.mjs";
import { useTokenSaleFactory } from "./composables/useTokenSaleFactory";
import { useCurrencies } from "./composables/useCurrencies";
import { OpenAPI } from "./api/generated";
import { SHOW_COUNTDOWN } from "@/config";

const { smAndDown } = useDisplay();
const loadingApp = ref(true);
const { initSdk, staticAeSdk, getSdk } = useAeppSdk();
const { activeNetwork } = storeToRefs(useNetworkStore());
const { checkWalletConnection } = useWalletConnect();
const { loadAeternityData, loadCurrencyRates } = useCurrencies();
const { initTokenSaleFactory } = useTokenSaleFactory();

watch(
  activeNetwork,
  async (network, prevNetwork) => {
    if (SHOW_COUNTDOWN || !network) {
      return;
    }
    OpenAPI.BASE = `${network.apiUrl}`;
    try {
      await loadAeternityData();
      await loadCurrencyRates();
    } catch (e) {
      console.error("Error loading data", e);
    }
    try {
      await initSdk();
      const sdk = await getSdk();
      sdk.selectNode(network.name);
      staticAeSdk.selectNode(network.name);
      checkWalletConnection();
      initTokenSaleFactory();
    } catch (e) {
      //
    }
    loadingApp.value = false;
    if (network?.websocketUrl !== prevNetwork?.websocketUrl) {
      console.log("connecting WS to::", network);
      WebSocketClient.disconnect();
      WebSocketClient.connect(network.websocketUrl);
    }
  },
  { immediate: true }
);
</script>

<style lang="scss">
button,
a.v-btn {
  font-weight: 500 !important;
}
</style>
