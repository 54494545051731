import type { INetwork, INetworkTypes } from "@/utils/types";

import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { NETWORKS, NETWORK_ID_TESTNET } from "@/utils/constants";

export const useNetworkStore = defineStore("networks", () => {
  const activeNetworkId = ref<INetworkTypes>(NETWORK_ID_TESTNET);

  const activeNetwork = computed<INetwork>(() => {
    return NETWORKS[activeNetworkId.value];
  });

  return {
    activeNetwork,
    activeNetworkId,
  };
});
