<template>
  <img :src="logo" style="max-width: 100%; height: 100%" />
</template>

<script lang="ts" setup>
import largeLightLogo from "@/assets/logo/token-org-logo.svg";
import largeDarkLogo from "@/assets/logo/token-org-logo-dark.svg";
import smallLightLogo from "@/assets/logo/token-org-logo-small.svg";
import smallDarkLogo from "@/assets/logo/token-org-logo-small-dark.svg";
import verticalLightLogo from "@/assets/logo/token-org-logo.svg";
import verticalDarkLogo from "@/assets/logo/token-org-logo-dark.svg";
import { storeToRefs } from "pinia";
import { useAppStore } from "@/stores/app";
import { computed, defineProps } from "vue";

const props = defineProps<{
  sm?: boolean;
  vertical?: boolean;
}>();

const { darkMode } = storeToRefs(useAppStore());

const logo = computed(() => {
  const largeLogo = darkMode.value ? largeDarkLogo : largeLightLogo;
  const smallLogo = darkMode.value ? smallDarkLogo : smallLightLogo;
  const verticalLogo = darkMode.value ? verticalLightLogo : verticalDarkLogo;

  if (props.sm) {
    return smallLogo;
  }

  if (props.vertical) {
    return verticalLogo;
  }

  return largeLogo;
});
</script>
