import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 0 }


import { useWalletConnect } from "@/composables/useWalletConnect";
import { storeToRefs } from "pinia";
import { useAccounts } from "@/stores/accounts";
import superheroWalletIcon from "@/assets/icons/sh-wallet.svg";
import { useDisplay } from "vuetify/lib/framework.mjs";

export default /*@__PURE__*/_defineComponent({
  __name: 'WalletConnect',
  props: {
    hideTextOnSm: { type: Boolean },
    connectWalletText: {}
  },
  setup(__props: any) {

const { smAndDown } = useDisplay();
const { connectWallet, scanningForWallets } = useWalletConnect();
const { activeAccount } = storeToRefs(useAccounts());
const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (!_unref(activeAccount))
    ? (_openBlock(), _createBlock(_component_v_btn, {
        key: 0,
        color: "primary",
        variant: "outlined",
        "data-cy": "connect-wallet",
        loading: _unref(scanningForWallets),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(connectWallet)()))
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: _unref(superheroWalletIcon),
            style: {"height":"20px"},
            class: _normalizeClass({ 'mr-2': !_unref(smAndDown) })
          }, null, 10, _hoisted_1),
          (!(_unref(smAndDown) && !_ctx.hideTextOnSm))
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.connectWalletText ?? "Connect Wallet"), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["loading"]))
    : _createCommentVNode("", true)
}
}

})