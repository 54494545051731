import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-h4 py-4 d-flex ga-1 align-center flex-wrap" }
const _hoisted_2 = { class: "pb-4 d-flex ga-1 align-center flex-wrap" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

import WalletConnect from "@/components/WalletConnect/WalletConnect.vue";
import AddressChip from "@/components/Common/AddressChip.vue";

import { useAeppSdk } from "@/composables/aeppSdk";
import { useNetwork } from "@/composables/useNetwork";
import { nodes } from "@/config";
import { Decimal } from "@/libs/decimal";

import { useAccounts } from "@/stores/accounts";
import {
  AeSdk,
  AeSdkAepp,
  CompilerHttp,
  MemoryAccount,
} from "@aeternity/aepp-sdk";
import { storeToRefs } from "pinia";
import { initCommunityFactory } from "token-gating-sdk";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useTokenSaleFactory } from "@/composables/useTokenSaleFactory";
import { useInvitation } from "@/composables/useInvitation";

import LivePriceFormatter from "@/components/Common/Pricing/LivePriceFormatter.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CollectInvitationLinkCard',
  setup(__props) {

const router = useRouter();

const { getAeSdk } = useAeppSdk();
const { activeNetwork } = useNetwork();
const { activeAccount } = storeToRefs(useAccounts());
const { activeTokenSaleFactoryAddress } = useTokenSaleFactory();

const invitationAmount = ref<Decimal>(Decimal.ZERO);
const invitationSender = ref();
const invitationClaimed = ref(false);

const loadingInvitation = ref(false);
const claimingReward = ref(false);

const errorMessage = ref();
const successMessage = ref();

const { invitationCode } = useInvitation();

watch(
  invitationCode,
  (invCode) => {
    if (invCode) {
      getInvitationRewardAmount();
    }
  },
  {
    immediate: true,
  }
);

watch(activeNetwork, () => {
  if (invitationCode.value) {
    getInvitationRewardAmount();
  }
});

/**
 * Retrieves the affiliation contract from the room factory.
 *
 * @param {AeSdk | AeSdkAepp} sdk - The AeSdk or AeSdkAepp instance.
 * @returns {Promise<AffiliationTreasury>} The affiliation treasury instance.
 */
async function getAffiliationTreasury(sdk: AeSdk | AeSdkAepp) {
  const factory = await initCommunityFactory(
    sdk,
    activeTokenSaleFactoryAddress.value
  );

  return await factory.affiliationTreasury();
}

/**
 * Retrieves the invitation reward amount for a given invitation code.
 * This function sets the loading state, initializes the AeSdk, retrieves the affiliation contract,
 * and updates the invitation details if the invitation code exists.
 * If the invitation has already been claimed, it displays an error message and closes the component after 3 seconds.
 * @throws {Error} If there is an error retrieving the invitation reward amount.
 */
async function getInvitationRewardAmount() {
  errorMessage.value = null;
  loadingInvitation.value = true;
  try {
    const account = new MemoryAccount(invitationCode.value);
    const sdk = new AeSdk({
      onCompiler: new CompilerHttp("https://v7.compiler.aepps.com"),
      nodes,
      accounts: [account],
    });
    sdk.selectNode(activeNetwork.value.name);

    const affiliationTreasury = await getAffiliationTreasury(sdk);

    const invitationCodes = await affiliationTreasury
      .invitationCodes()
      .catch(() => ({}));

    const invitation = invitationCodes.get(account.address);
    if (invitation) {
      invitationSender.value = invitation[0];
      invitationAmount.value = Decimal.fromBigNumberString(invitation[1]);
      invitationClaimed.value = invitation[2];

      if (invitationClaimed.value) {
        errorMessage.value = "This invitation has already been claimed.";

        setTimeout(() => {
          onClose();
        }, 3000);
      }
    }
  } catch (error: any) {
    console.log("ERROR", error);
    if (error?.message?.includes("Trying to call undefined function")) {
      errorMessage.value = "Please switch to the correct network.";
    } else {
      errorMessage.value = error?.message;
    }
  }
  loadingInvitation.value = false;
}

async function claimReward() {
  claimingReward.value = true;
  const sdk = await getAeSdk();
  const affiliationTreasury = await getAffiliationTreasury(sdk);

  try {
    await affiliationTreasury.redeemInvitationCode(
      invitationCode.value,
      activeAccount.value
    );
    successMessage.value = "Reward claimed successfully.";
    invitationClaimed.value = true;
  } catch (error: any) {
    if (error?.message?.includes("INVITEE_ALREADY_REGISTERED")) {
      errorMessage.value =
        "You can't claim this reward with the current active account, try with another account.";
    }
  }
  claimingReward.value = false;
}

function onClose() {
  localStorage.removeItem("invite_code");
  router.push({ query: { invite_code: null } });
  invitationCode.value = null;
}

return (_ctx: any,_cache: any) => {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (!!_unref(invitationCode))
    ? (_openBlock(), _createBlock(_component_v_card, {
        key: 0,
        class: "mb-4 custom-card"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-h3 pb-4" }, "YOU HAVE BEEN INVITED TO TOKAEN.ORG", -1)),
              _cache[8] || (_cache[8] = _createElementVNode("p", null, " Easily create and manage Token Sales with a bonding curve ensuring fair pricing. Engage in fair token launches, participate in community governance through integrated DAOs, and maximize rewards with a robust referral system. ", -1)),
              _cache[9] || (_cache[9] = _createElementVNode("p", null, " Grow vibrant token-gated communities for businesses, projects, DAOs, or even meme coins. Step into the future of decentralized token management! ", -1)),
              _createElementVNode("div", _hoisted_1, [
                _cache[2] || (_cache[2] = _createTextVNode(" CLAIM YOUR ")),
                _createVNode(LivePriceFormatter, {
                  "ae-price": invitationAmount.value,
                  "watch-price": false,
                  "price-loading": loadingInvitation.value,
                  class: "text-primary text-decoration-underline",
                  row: ""
                }, null, 8, ["ae-price", "price-loading"]),
                _cache[3] || (_cache[3] = _createTextVNode(" REWARD "))
              ]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(AddressChip, { address: invitationSender.value }, null, 8, ["address"]),
                _cache[4] || (_cache[4] = _createTextVNode(" has invited you to join Tokaen.org, you will earn a ")),
                _createVNode(LivePriceFormatter, {
                  "ae-price": invitationAmount.value,
                  "watch-price": false,
                  "price-loading": loadingInvitation.value,
                  row: ""
                }, null, 8, ["ae-price", "price-loading"]),
                (!_unref(activeAccount))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, " reward when connecting your Superhero Wallet."))
                  : (_openBlock(), _createElementBlock("span", _hoisted_4, " reward."))
              ]),
              (errorMessage.value)
                ? (_openBlock(), _createBlock(_component_v_alert, {
                    key: 0,
                    icon: "$error",
                    color: "error",
                    class: "mb-5",
                    border: "start",
                    title: "Oops! Something went wrong.",
                    text: errorMessage.value
                  }, null, 8, ["text"]))
                : _createCommentVNode("", true),
              (successMessage.value)
                ? (_openBlock(), _createBlock(_component_v_alert, {
                    key: 1,
                    icon: "$success",
                    color: "success",
                    class: "mb-5",
                    border: "start",
                    title: "Success!",
                    text: successMessage.value
                  }, null, 8, ["text"]))
                : _createCommentVNode("", true),
              (_unref(activeAccount))
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 2,
                    size: "large",
                    color: "primary",
                    disabled: invitationClaimed.value,
                    loading: claimingReward.value,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (claimReward()))
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode(" Claim AE ")
                    ])),
                    _: 1
                  }, 8, ["disabled", "loading"]))
                : _createCommentVNode("", true),
              _createVNode(WalletConnect, {
                "connect-wallet-text": "Connect wallet to claim reward",
                size: "large"
              }),
              (_unref(activeAccount))
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 3,
                    size: "large",
                    color: "primary",
                    variant: "outlined",
                    class: "ml-4",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (onClose()))
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode(" Dismiss ")
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})