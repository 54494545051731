<template>
  <div>
    <v-menu max-height="400px">
      <template v-slot:activator="{ props }">
        <v-chip v-bind="props">
          <span class="text-uppercase">{{ currentCurrencyCode }}</span>
          <v-icon>mdi-chevron-down</v-icon>
        </v-chip>
      </template>
      <v-list>
        <template v-for="currency in CURRENCIES" :key="currency">
          <v-list-item
            v-if="currentCurrencyCode !== currency.code"
            :value="currency"
            @click="setCurrentCurrency(currency.code)"
          >
            <v-list-item-title>
              {{ currency.name }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts" setup>
import { useCurrencies } from "@/composables/useCurrencies";
import { CURRENCIES } from "@/utils/constants";

const { setCurrentCurrency, currentCurrencyCode } = useCurrencies();
</script>
