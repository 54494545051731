<template>
  <v-app-bar app fixed location="top" class="ga-2">
    <div class="container d-flex align-center ga-2">
      <div class="d-flex align-center">
        <div class="logo-btn" variant="plain" @click="onLogoClick()">
          <AppLogo :sm="smAndDown" style="height: 35px" />
        </div>

        <v-btn v-if="!smAndDown" class="ml-6" color="primary" to="/" exact
          >Token List</v-btn
        >
        <v-btn v-if="!smAndDown" color="primary" to="/create-token"
          >Create new token</v-btn
        >
        <v-btn v-if="!smAndDown" color="primary" to="/invite" exact
          >Invite</v-btn
        >
        <v-btn v-if="!smAndDown" color="primary" to="/about" exact>About</v-btn>
      </div>
      <v-spacer />
      <div class="d-flex align-center ga-4 px-2">
        <HeaderCurrencySelector />
        <ToggleDarkMode />
        <ActiveNetwork />
        <WalletConnect />
        <HeaderAccountCard />
      </div>
    </div>
  </v-app-bar>
</template>

<script lang="ts" setup>
import HeaderAccountCard from "@/components/Account/HeaderAccountCard.vue";
import WalletConnect from "@/components/WalletConnect/WalletConnect.vue";
import ToggleDarkMode from "@/components/Header/ToggleDarkMode.vue";
import ActiveNetwork from "@/components/Header/ActiveNetwork.vue";
import HeaderCurrencySelector from "@/components/Header/HeaderCurrencySelector.vue";
import AppLogo from "@/components/Common/AppLogo.vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { useRouter } from "vue-router";
const { smAndDown } = useDisplay();

const router = useRouter();

function onLogoClick() {
  router.push("/");
}
</script>

<style scoped lang="scss">
.logo-btn {
  opacity: 1;
  padding-top: 6px;
  &:hover {
    cursor: pointer;
  }
}
@media screen and (max-width: 1681px) {
  .logo-btn {
    padding-left: 14px;
  }
}
@media screen and (max-width: 960px) {
  .logo-btn {
    padding-left: 12px;
  }
}
</style>
