import { TokenDto } from "@/api/generated";
import WebSocketClient from "@/libs/WebSocketClient";
import { useQueryClient } from "@tanstack/vue-query";
import { onMounted, onUnmounted, ref } from "vue";

export interface ILiveTokenData {
  token: TokenDto;
  onUpdate?: (token: TokenDto) => void;
}
/**
 * Custom composable function that provides live token data for a given token.
 * @param token - The token object.
 * @returns An object containing the reactive token data.
 */
export function useLiveTokenData({ token, onUpdate }: ILiveTokenData) {
  const tokenData = ref<TokenDto>({
    ...token,
  });

  const subscription = ref();
  const queryClient = useQueryClient();

  onMounted(() => {
    subscription.value = WebSocketClient.subscribeForTokenUpdates(
      token.sale_address,
      (tx: any) => {
        console.log("Token update", tx);
        const newTokenData = {
          ...tokenData.value,
          ...tx.data,
        };

        queryClient.refetchQueries({
          queryKey: ["TokensService.findByAddress", token.sale_address],
        });
        queryClient.refetchQueries({
          queryKey: [
            "TransactionsService.listTransactions",
            token.sale_address,
          ],
        });
        queryClient.refetchQueries({
          queryKey: ["TokensService.getHolders", token.sale_address],
        });

        tokenData.value = newTokenData;
        onUpdate?.(newTokenData);
      }
    );
  });

  onUnmounted(() => {
    subscription?.value?.();
  });

  return {
    tokenData,
  };
}
